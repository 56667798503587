<template>
    <breadcrumb btnText="新建管理员" @btn-click="$router.push('admins/create')"></breadcrumb>

    <div class="admins-page">
        <table class="table">
            <thead>
                <td width="60">id</td>
                <td width="160">用户名</td>
                <td width="80">头像</td>
                <td width="220">邮箱</td>
                <td width="220">手机号</td>
                <td width="160">账户状态</td>
                <td>创建时间</td>
                <td width="140">操作</td>
            </thead>
            <tbody>
                <tr v-for="(item, index) in list.data" :key="index">
                    <td>{{ item.id }}</td>
                    <td>{{ item.name }}</td>
                    <td>
                        <n-avatar :size="40" :src="item.avatar" :name="item.name"></n-avatar>
                    </td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.phone }}</td>
                    <td>{{ item.status === 'active' ? '激活' : '未激活' }}</td>
                    <td>{{ item.created_at }}</td>
                    <td>
                        <div class="operation">
                            <n-button class="btn" 
                                type="primary" 
                                @click="$router.push(`admins/edit/${item.id}`)"
                            >
                                <edit theme="outline" size="16" fill="#fff"/>
                            </n-button>
                            <n-popconfirm 
                                position="bottom right"
                                content="确定永久删除该管理员吗？"
                                @confirm="handlerDelete(item.id, index)"
                            >
                                <n-button class="btn" type="danger">
                                    <delete theme="outline" size="16" fill="#fff"/>
                                </n-button>
                            </n-popconfirm>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <no-data v-if="!list.data"></no-data>
        <!-- 翻页 -->
        <pagination v-if="list.links" v-model:list="list"></pagination>
    </div>

    <n-notification 
        v-model:show="notification.show"
        :content="notification.content"
    >
        <template v-slot:icon>
            <attention v-if="notification.state === 'error'" theme="outline" size="24" fill="#ff2121"/>
            <check-one v-else theme="outline" size="24" fill="#00c12b"/>
        </template>
    </n-notification>
    
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import NoData from '@/components/layout/NoData.vue';
import Pagination from '@/components/layout/Pagination.vue';
import { Edit, Delete, Attention, CheckOne } from '@icon-park/vue-next';

@Options({
    components: {
        Breadcrumb, NoData, Pagination,
        Edit, Delete, Attention, CheckOne
    },
    data(){
        return{
            list: {},
            notification: {
                show: false,
                content: '',
                state: 'error'
            }
        }
    },
    mounted(){
        this.getAdminsList();
    },
    methods: {
        getAdminsList(){
            this.$api.getAdmins().then((res: any) => {
                this.list = res.data;
            })
        },
        handlerDelete(id: number, index: number){
            this.$api.destroyAdmin(Number(id)).then((res: any) => {
                console.log(res)
                if(res.msg === 'success') {
                    this.list.data.splice(index, 1);
                    this.notification = {
                        show: true,
                        content: '管理员删除成功',
                        state: 'success'
                    }
                } else {
                    this.notification = {
                        show: true,
                        content: res.msg,
                        state: 'error'
                    }
                }
            })
        }
    }
})

export default class Admins extends Vue {}
</script>

<style lang="scss" scoped>
.admins-page{
    @extend .flex-column;
    margin: 10px;
    padding: 10px;
    border-radius: $radius;
    background-color: $white;
    box-shadow: $shadow-default;
}
</style>